$( document ).ready(function() {
    initEditor();
});

async function initEditor(selector = false) {
    if ($(".ck").length || selector !== false) {
        //new Jodit("#ckeditor");
		let opts = $.extend({}, PageItEditor.options, {
			toolbar: [
				'undo redo | formatselect forecolor blockquote | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | visualblocks visualchars fullscreen piCode',
				'hr | bullist numlist outdent indent | table',
				PageItEditor.toolbar
			],
			menubar: false,
			menu: false,
			plugins: [
				'advlist autolink link image lists charmap preview hr anchor pagebreak',
				'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
				'table directionality paste',
				PageItEditor.customPlugins.join(" ")
			]
		});
        // Replace CSS        
        // opts["content_css"] = [];
        try {
            let response = await $.ajax({
                "url": "api/admin/editorcss"
            });
            opts["content_css"] = response.css;
        } catch (e) {
            console.error(e);
        }

        // Selector?
        if (selector !== false) {
            opts["selector"] = selector;
        }
		tinymce.init(opts);
    }
};


function inlineEdit(elem) {
    tinymce.init({
        selector: elem,		
        relative_urls : true,
        valid_elements : '*[*]',
        inline: true,
        body_class: "editor-body",
        document_base_url : "/",
        convert_urls : false,
        toolbar: [
            'undo redo | formatselect blockquote | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | visualblocks visualchars fullscreen code',
            'link image hr | bullist numlist outdent indent | table',
            PageItEditor.toolbar
        ],
        menubar: false,
        menu: false,
        plugins: [
            'advlist autolink link image lists charmap preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
            'table contextmenu directionality paste textcolor',
            PageItEditor.customPlugins.join(" ")
        ]
    });
}


function convertToSlug(text) {
    return text
        .toLowerCase()
        .replace(/[^\w ]+/g,'')
        .replace(/ +/g,'-')
        ;
}