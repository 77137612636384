$("body").off("click", "#admin_select_favicon");
$("body").on("click", "#admin_select_favicon", function() {
    var selectDlg = new Dialog();
    selectDlg.fileBrowser(
        "Favicon auswählen",
        "Wählen Sie das Bild aus, welches Sie als Favicon verwenden möchten.",
        "pictures",
        new Array(),
        true,
        function(pic) {
            $("#favicon_preview").css("display", "inline-block");
            $("#favicon_preview").attr("src", pic.thumbnail);
            $("#favicon_preview").parent().find("span").text(`
                #${pic.id} - ${pic.vpath}
            `);
            $("#favicon_preview").parent().find("input").val(pic.id);
            selectDlg.close();
        }, 
        function() {
            selectDlg.close();
        },
        {
            upload: true,
            foldercreation: true
        }
    );
});

$("body").off("click", "#admin_delete_favicon");
$("body").on("click", "#admin_delete_favicon", function() {
    $("#favicon_preview").hide();
    $("#favicon_preview").parent().find("span").text("Kein Icon gewählt");
    $("#favicon_preview").parent().find("input").val("-1");
});

$("body").off("click", "#admin_select_banner");
$("body").on("click", "#admin_select_banner", function() {
    var selectDlg = new Dialog();
    selectDlg.fileBrowser(
        "Banner auswählen",
        "Wählen Sie das Bild aus, welches Sie als Banner verwenden möchten.",
        "pictures",
        new Array(),
        true,
        function(pic) {
            $("#banner_preview").css("display", "inline-block");
            $("#banner_preview").attr("src", pic.thumbnail);
            $("#banner_preview").parent().find("span").text(`
                #${pic.id} - ${pic.vpath}
            `);
            $("#banner_preview").parent().find("input").val(pic.id);
            selectDlg.close();
            var grid = Masonry.data($(".auto-masonry")[0]);     
            grid.layout();
        }, 
        function() {
            selectDlg.close();
        },
        {
            upload: true,
            foldercreation: true
        }
    );
});

$("body").off("click", "#admin_delete_banner");
$("body").on("click", "#admin_delete_banner", function() {
    $("#banner_preview").hide();
    $("#banner_preview").parent().find("span").text("Kein Banner gewählt");
    $("#banner_preview").parent().find("input").val("-1");
    
    var grid = Masonry.data($(".auto-masonry")[0]);
    grid.layout();
});

$("body").off("click", "#rebuild-search-cache");
$("body").on("click", "#rebuild-search-cache", async function() {
    try {
        await $.ajax({
            "url": "api/search/update_index"
        });
        new Notification().info("Such-Index wurde neu erstellt");
    } catch (e) {
        console.log(e);        
        new Notification().error("Fehler beim erstellen des Such-Indexes");
    }
});