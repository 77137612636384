function pObjectSelect(button, deletebtn, input, selection, objectClass, object_name) {
    button.on("click", function() {
        var selectDlg = new Dialog();
        selectDlg.pObjectBrowser(
            object_name + " auswählen",
            "Wählen Sie das Objekt aus, welches Sie verwenden möchten.",
            objectClass,
            function(o) {
                $(selection).text(`
                    #${o.id} - ${o.objectName}
                `);
                input.val(o.id);
                selectDlg.close();
            }, 
            function() {
                selectDlg.close();
            }
        );
    });

    deletebtn.on("click", function() {
        $(selection).text("Kein Objekt gewählt");
        input.val("-1");
    });
}
function getDefaultFilter(objectclass, filtername, forceReload) {    
    switch (objectclass) {
        case "\\P\\Categories\\Category":  
            if (filtername.startsWith("parent-")) {
                let parentId = filtername.substr("parent-".length);
                return function(category) {
                    return category.path.includes(parentId);
                }
            }
            if (filtername === "any-building-or-room") {             
                return async function(category) {
                    let buildings = await CategorySemantics.getBuildings(true, forceReload);
                    let rooms = await CategorySemantics.getRooms(false, forceReload);
                    return (category.id in buildings || category.id in rooms);
                }
            }
            if (filtername === "private-building") {               
                return async function(category) {
                    let buildings = await CategorySemantics.getPrivateBuildings(forceReload);
                    return (category.id in buildings);
                }                
            }
            if (filtername === "building-or-room") {                
                return async function(category) {
                    let buildings = await CategorySemantics.getBuildings(false, forceReload);
                    let rooms = await CategorySemantics.getRooms(false, forceReload);                    
                    return (category.id in buildings || category.id in rooms);
                }
            }
            if (filtername.startsWith("semantic-")) {
                let semanticName = filtername.substr("semantic-".length);
                return async function(category) {
                    let semantics = await CategorySemantics.getSemantics(semanticName, forceReload);
                    for (let allowed of semantics[semanticName]) {
                        if (allowed.id == category.id) {
                            return true;
                        }
                    }
                    return false;
                }
            }
            break;
    };
    return false;
}

function getDefaultLabler(objectclass, filtername = false, forceReload = false, args = {}) {    
    let labelType = "dropdown";
    if ("type" in args) {
        labelType = args.type;
    }

    if (filtername !== false) {
        if (filtername.startsWith("locations:")) {
            let locationType = filtername.substr("locations:".length);
            switch (locationType) {
                case "district":
                    return async function(category) {
                        return category.name;
                    }
                case "building":
                    return async function(category) {
                        let district = await CategorySemantics.getAssociatedDistrict(category, forceReload);
                        let districtName = "";
                        if (district !== false) {
                            districtName = ` (${district.name})`;
                        }
                        return `${category.name}${districtName}`;
                    }
                case "room":
                case "building-or-room":
                    return async function(category) {
                        let building = await CategorySemantics.getAssociatedBuilding(category, forceReload);
                        let district = await CategorySemantics.getAssociatedDistrict(category, forceReload);
                        let additionals = [];
                        if (building !== false) {
                            additionals.push(building.name);
                        }
                        if (district !== false && labelType === "dropdown") {
                            additionals.push(district.name);
                        }
                        if (additionals.length > 0) {
                            return `${category.name} (${additionals.join(' ')})`;
                        }
                        return category.name;
                    }
            } 
        }
        if (filtername === "categoryname") {
            return function(category) {
                return category.name;
            }
        }
        if (filtername === "indentedcategoryname") {
            return function(category) {
                let indent = "";
                if (labelType === "dropdown") {
                    indent = Array(category.path.length).join("&nbsp;&nbsp;&nbsp;");
                }
                return `${indent}${category.name}`;
            }
        }

        if (filtername === "page-hierarchy") {
            return function(page) {
                let indent = "";
                if (labelType === "dropdown") {
                    indent = Array(page.properties.parents.length).join("&nbsp;&nbsp;&nbsp;");
                }
                return `${indent}${page.properties.title}`;
            }
        }
    }

    switch (objectclass) {
        case "\\P\\Categories\\Category":
            switch (labelType) {
                case "dropdown":
                case "long":
                    return function(category) { 
                        let indent = Array(category.level + 1).join("&nbsp;&nbsp;&nbsp;");
                        let path = "";
                        if (category.parentNames.length > 0) {
                            path = " (" + category.parentNames.join(" → ") + ")";
                        }
                        return indent + category.name + path +  " (#" + category.id + ")";
                    };    
                case "short":
                case "selected":
                    return function(category) {      
                        return category.name +  " (#" + category.id + ")";
                    };    
            }
    }
    return false;
}

function getDefaultSorter(objectclass, filtername = false, forceReload = false, args = {}) {
    if (filtername === "page-hierarchy") {
        return function(pages) {
            return pages.sort((a, b) => {
                return a.properties.path.localeCompare(b.properties.path);
            });
        }
    }
    switch (objectclass) {
        case "\\P\\Categories\\Category":            
            return function(categories) { 
                return categories.sort((a, b) => {return a.position - b.position});
            };            
    }    
    return false;
}

const pObjectCache = new RequestCache();

async function loadPObjectList(objectclass, forceReload = false) {
    let unlock = await pObjectCache.lock(objectclass);
    if (!forceReload && pObjectCache.hasCache(objectclass)) {        
        unlock();
        return pObjectCache.getCache(objectclass, false);
    } else {
        try {             
            let result = await $.ajax({
                url: "api/admin/pobject_list",
                type: "POST",
                data: {
                    "class": objectclass
                }
            });
            return pObjectCache.cacheAndUnlock(objectclass, result.objects, unlock);            
        } catch (e) {
            console.error(e);
            unlock();
            return false;
        }
    }
}

function getDefaultPObjectSelectCallback(object_class, callback_value, default_callback, forceReload, args = {}) {
    if (callback_value !== false) {
        if (callback_value.startsWith("default")) {
            callback_value = callback_value.substr("default".length);
            if (callback_value.startsWith(":")) {
                callback_value = callback_value.substr(1);
            }
            callback_value = default_callback(object_class, callback_value, forceReload, args);
        } else {
            callback_value = eval(callback_value);
        }
    }
    return callback_value;
}

async function initAdminPObjectSelect(element, forceReload = false, data = {}) {
    for (let key in data) {
        $(element).data(key, data[key]);
    }
    
    let inputid = $(element).data("input");
    let val = $(element).data("value");
    if (val === "") {
        val = -1;
    }
    let title = $(element).data("title") ? $(element).data("title") : "";
    let name = $(element).data("name") ? $(element).data("name") : "";
    let object_class = $(element).data("objectclass") ? $(element).data("objectclass" ) : "";
    let object_name = $(element).data("objectname") ? $(element).data("objectname" ) : "";
    let object_filter = $(element).data("objectfilter") ? $(element).data("objectfilter" ) : false;
    let object_sorter = $(element).data("objectsorter") ? $(element).data("objectsorter" ) : "default";
    let object_labler_name = $(element).data("objectlabler") ? $(element).data("objectlabler" ) : false;
    let object_selected_labler = $(element).data("objectselectedlabler") ? $(element).data("objectselectedlabler" ) : false;
    let selectCount = $(element).data("selectcount") ? $(element).data("selectcount") : 1;
    let object_labler = false;
    object_filter = getDefaultPObjectSelectCallback(object_class, object_filter, getDefaultFilter, forceReload);
    object_sorter = getDefaultPObjectSelectCallback(object_class, object_sorter, getDefaultSorter, forceReload);
    object_labler = getDefaultPObjectSelectCallback(object_class, object_labler_name, getDefaultLabler, forceReload, {"type": "dropdown"});
    if (!object_selected_labler) {
        object_selected_labler = getDefaultPObjectSelectCallback(object_class, object_labler_name, getDefaultLabler, forceReload, {"type": "selected"});
    } else {
        object_selected_labler = getDefaultPObjectSelectCallback(object_class, object_selected_labler, getDefaultLabler, forceReload, {"type": "selected"});
    }

    let selectOptions = {};
    let multiple = "";
    let noOption = `<option value="-1">Kein(e) ${object_name} gewählt</option>`;
    if (selectCount > 1) {
        name = name + "[]";
        multiple = 'multiple="multiple"';
        noOption = "";
        selectOptions["closeOnSelect"] = false;
    }

    selectOptions["templateSelection"] = function(state) {
        if ($(state.element).data("short-label")) {
            return $(state.element).data("short-label");
        }
        return state.text;
    }
    
    $(element).html(`
        <label>${title}</label>
        <div class="valign-wrapper" style="justify-content: space-between; gap: 10px;">
            <select name="${name}" id="${inputid}" class="browser-default" ${multiple}>
                ${noOption}
            </select>
            <div class="btn red darken-2 adminpobjectselect_delete">
                <i class="material-icons">delete</i>
            </div>
        </div>
    `);
    
    let btn = $(element).find(".adminpobjectselect_select");
    let del = $(element).find(".adminpobjectselect_delete");
    let input = $(element).find(`#${inputid}`);
    let selection = $(element).find(".selection");

    del.on("click", function() {
        input.val("-1").trigger("change");
    });

    let objects = await loadPObjectList(object_class, forceReload);
    if (!objects) {
        new Notification().error("Laden von " + object_name + " fehlgeschlagen");
    } else {
        if (object_sorter !== false) {
            objects = object_sorter(objects);
        }

        for (let o of objects) {
            if (object_filter !== false) {
                if (! await object_filter(o)) {
                    continue;
                }
            }
            let label = `#${o.id} - ${o.objectName}`;
            let selectedLabel = `#${o.id} - ${o.objectName}`;
            if (object_labler !== false) {
                label = await object_labler(o);
            }
            if (object_selected_labler !== false) {
                selectedLabel = await object_selected_labler(o);
            }
            input.append(`
                <option data-short-label="${selectedLabel}" value="${o.id}">${label}</option>
            `);
        }
        //input.find(`option[value="${val}"]`).attr("selected", "selected");
        input.val(val);
        input.trigger("change");
        input.select2(selectOptions);
        updategrids();
    }
}

var pObjectInputId = 0;
function preparePObjectSelect(options) {
    let value = options.value ?? "";
    let selectCount = options.selectCount ?? 1;
    if (selectCount > 1 || selectCount === "*") {
        if (value === "") {
            value = "[]";
        } else if (Array.isArray(value)) {
            value = JSON.stringify(value);
        }
    }
    let input = options.input ?? `pobject-input-default-id-${pObjectInputId++}`;
    let divClass = options.divClass ?? "";
    let asJquery = options.asJquery ?? false;


    let html = `
        <div class="${divClass} adminpobjectselect"
            data-value='${value}'
            data-input="${input}"
            data-selectcount="${selectCount}"
            data-objectclass="${options.objectclass}"
            data-title="${options.title ?? ""}"
            data-name="${options.name ?? ""}"
            data-objectname="${options.objectname ?? ""}"
            data-objectfilter="${options.objectfilter ?? ""}"
            data-objectsorter="${options.objectsorter ?? ""}"
            data-objectlabler="${options.objectlabler ?? ""}"
        ></div>
    `;
    if (asJquery) {
        return $(html);
    }
    return html;
}

$(function() {
    $(".adminpobjectselect").each(function() {
        initAdminPObjectSelect($(this));
    });
});
