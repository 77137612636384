$(function() {
    $("body").on("click", ".savesettingbtn", function() {
        var valcount = $(this).data("value-count") ? $(this).data("value-count") : 1;
        var total = 0;
        var success = 0;
        var failed = 0;
        
        for (var i = 0; i < valcount; ++i) {        
            var postfix = "";
            if (valcount > 1) {
                postfix = "-" + String.fromCharCode(97 + i);;
            }
            console.log("value-type" + postfix);
            var valtype = $(this).data("value-type" + postfix);
            var valuesource = $(this).data("value" + postfix);
            var key = $(this).data("key" + postfix);
            var value = "";
            var savetype = "default";
            
            switch (valtype) {
                case "input":
                case "textarea":
                    value = $(valuesource).val();
                    break;
                case "radio":
                    value = $(valuesource + ":checked").val();
                    break;
                case "checkbox":
                    value = $(valuesource).is(":checked");
                    savetype = "boolean";
                    break;
                case "codemirror":
                    value = window[valuesource].getValue();
                    break;
                case "plain":
                    value = valuesource;
                    break;
                default:
                    new Notification().warning(`Ungültiger Wertetyp ${valtype}`);
                    return;
            }
            
            var showStats = function(success, failed, total) {
                if (total == 1) {
                    if (success == 1) {
                        new Notification().success("Einstellung gespeichert");
                    } else {
                        new Notification().error("Einstellung konnte nicht gespeichert werden");
                    }
                } else {
                    if (success == total) {
                        new Notification().success("Alle " + total + " Einstellungen gespeichert");
                    } else if (failed == total) {
                        new Notification().error("Alle " + total + " Einstellungen konnten nicht gespeichert werden");
                    } else {
                        new Notification().success((success > 1 ? success + " Einstellungen" : "Eine Einstellung") + " gespeichert");
                        new Notification().error((failed > 1 ? failed + " Einstellungen" : "Eine Einstellung") + " konnte nicht gespeichert werden");
                    }
                }
            }
            
            $.ajax({
                url: "api/admin/savesetting",
                type: "POST",
                data: {
                    key: key,
                    value: value,
                    savetype: savetype
                },
                success: function() {
                    //new Notification().success("Einstellung gespeichert");            
                    total++;
                    success++;
                    if (total == valcount) {
                        showStats(success, failed, total);
                    }
                },
                error: function() {
                    total++;
                    failed++;
                    if (total == valcount) {
                        showStats(success, failed, total);
                    }
                }
            });
        }
    });
});