class RequestCache {
    constructor() {
        this.objectCache = {};
        this.mutexes = {};
    }

    getMutex(name) {
        if (!(name in this.mutexes)) {
            this.mutexes[name] = new Mutex();
        }
        return this.mutexes[name];
    }

    async lock(name) {
        return await this.getMutex(name).acquire();
    }

    cache(name, data) {
        this.objectCache[name] = data;
        return data;
    }

    cacheAndUnlock(name, data, unlock) {
        let result = this.cache(name, data);
        unlock();
        return result;
    }

    hasCache(name) {
        return name in this.objectCache;
    }

    getCache(name, defaultValue=false) {
        if (this.hasCache(name)) {
            return this.objectCache[name];
        }
        return defaultValue;
    }
}
