class PictureMultiSelect {
    constructor(selectedPicturesIds = [], options = {}) {
        this.selectedPicturesIds = selectedPicturesIds;
                
        this.cache = {};

        this.options = {
            "allowDuplicates": false,
            "triggerButton": null,
            "input": null,
            "preview": null,
        }
        this.options = Object.assign(this.options, options);
        this.mainDialog = null;
        this.addDialog = null;

        if (this.options.triggerButton !== null) {
            this.options.triggerButton.on("click", function() {this.showManage()}.bind(this));
        }
        if (this.options.preview !== null) {
            this.showPreview(this.options.preview);
        }
    }

    apply() {        
        let that = this;
        this.selectedPicturesIds = [];
        if (this.mainDialog !== null) {
            this.mainDialog.getNode().find(".picture_multi_select_manager").children().each(function() {
                that.selectedPicturesIds.push($(this).attr("data-picture"));
            });
        }
        if (this.options.input !== null) {
            this.options.input.val(JSON.stringify(this.selectedPicturesIds));
        }
        if (this.options.preview !== null) {
            this.showPreview(this.options.preview);
        }
        this.mainDialog.close();
    }

    async showPreview(container) {
        container = $(container);
        container.html("");
        let pictures = await this.getSelectedPictures();
        if (pictures.length === 0) {
            container.append(`<i>Keine Bilder ausgewählt</i>`);
            return;
        }
        for (let index in pictures) {
            container.append(this._makePictureDiv(pictures[index], index, false));
        }
    }

    async getSelectedPictures() {
        if (this.selectedPicturesIds.length === 0) {
            return [];
        }

        let picturesToQuery = [];
        for (let pictureId of this.getSelectedPictureIds()) {
            if (!(pictureId in this.cache)) {
                picturesToQuery.push(pictureId);
            }
        }

        if (picturesToQuery.length > 0) {
            try {
                let pictureInfos = await $.ajax({
                    "url": "api/pictures/multiinfo",
                    "type": "post",
                    "data": {
                        "ids": picturesToQuery
                    }
                });
                this.cache = Object.assign(this.cache, pictureInfos.pictures);
            } catch (e) {
                new Notification().error("Bilder konnten nicht geladen werden");
            }
        }
        
        let pictures = [];
        for (let pictureId of this.getSelectedPictureIds()) {
            if (pictureId in this.cache) {
                pictures.push(this.cache[pictureId]);
            }
        }
        return pictures;
    }

    getSelectedPictureIds() {
        return this.selectedPicturesIds;
    }

    selectPicture(picture) {
        this.selectedPicturesIds.push(picture.id);
    }

    deselectPictureById(pictureId) {
        this.selectedPicturesIds = this.selectedPicturesIds.filter((e) => {e !== pictureId});
    }

    deselectPictureByIndex(index) {
        if (this.getSelectedPictureIds().length <= index) {
            return false;
        }
        if (index < 0) {
            return false;
        }
        this.selectedPicturesIds.splice(index, 1);
        return true;
    }

    _makePictureDiv(pic, index, deletable = true) {
        let deleteDiv = "";
        if (deletable) {
            deleteDiv = `
            <div class="picture_delete" data-picture="${pic.id}" data-index="${index}">
                <div class="picture_delete_cursor"></div>
            </div>`;
        }
        return `
            <div class="picture_multi_select_picture picture_element" id="picture_multi_select_picture_${pic.id}" type="picture" data-picture="${pic.id}" title="${pic.name}" t="${pic.thumbnail}" l="${pic.large}" m="${pic.medium}">
                ${deleteDiv}
                <div class="picture_content">
                    <img src="${pic.thumbnail}" />
                </div>
            </div>
        `;
    }

    async showManage(forceManage = false) {
        if (this.mainDialog !== null) {
            this.mainDialog.close();
        }

        let pictures = [];
        let pictureInfos = await this.getSelectedPictures();
        for (let index in pictureInfos) {
            pictures.push(this._makePictureDiv(pictureInfos[index], index));
        }

        let cancel = function() {
            if (this.mainDialog !== null) {
                this.mainDialog.close();
            }
        }

        this.mainDialog = new Dialog();
        this.mainDialog.build()
            .setFullScreen(true)
            .addTitle(`Bilder Sortieren und Entfernen`)        
            .addHtml(`                    
                <div class="picture_multi_select_manager" id="picture_multi_select_manager">
                    ${pictures.join("")}
                </div>
            `)
            .addButton("Bestätigen", "save", this.apply.bind(this))
            .addButton("Bilder hinzufügen", "add", this.showAdd.bind(this))
            .addButton("Abbrechen", "cancel", cancel.bind(this))
            .onEscape(cancel.bind(this))
            .onEnter(this.apply.bind(this))
            .show();

        $("#picture_multi_select_manager").sortable();
        if (pictures.length === 0 && !forceManage) {
            this.showAdd();
        }
    }

    showAdd() {
        let that = this;
        if (that.mainDialog !== null) {
            that.mainDialog.close();
        }
        this.addDialog = new Dialog();
        let excludes = [];
        if (!this.options.allowDuplicates) {
            excludes = JSON.parse(JSON.stringify(this.getSelectedPictureIds()));
        }
        this.addDialog.fileBrowser(
            "Bilder auswählen", 
            "Wählen Sie Bilder aus, die Sie hinzufügen möchten.", 
            "pictures", 
            excludes, 
            this.options.allowDuplicates, 
            async function(file, browser) {
                if (file.type === "file") {
                    that.selectPicture(file);
                    new Notification().info("Bild ausgewählt");
                }
            }, 
            function() {
                that.addDialog.close();
                that.showManage(true);
            },
            {
                upload: true,
                foldercreation: true,
                folderselection: false
            }
        );
    }
}



function initAdminPictureMultiSelect(element) {
    let inputid = $(element).data("input");
    let val = $(element).attr("data-value");
    let width = $(element).data("width") ? $(element).data("width") + "px" : "100px";
    let title = $(element).data("title") ? $(element).data("title") : "";
    let name = $(element).data("name") ? $(element).data("name") : "";
    
    let ids = [];
    if (val !== "") {
        ids = JSON.parse(val);
    }

    $(element).html(`
        <label>${title}</label>
        <input type="hidden" id="${inputid}" name="${name}" value='${JSON.stringify(ids)}' />
        <div class="admin_picture_multi_select_preview">
            
        </div>
        <div class="right-align" style="margin-top: 10px;">
            <button type="button" class="btn blue white-text waves-effect admin_picture_multi_select_select">
                <i class="material-icons left">photo</i>
                Bearbeiten
            </button>
        </div>
    `);
    
    let btn = $(element).find(".admin_picture_multi_select_select");
    let preview = $(element).find(".admin_picture_multi_select_preview");
    let input = $(element).find("input");

    let multiSelect = new PictureMultiSelect(ids, {
        "triggerButton": btn,
        "input": input,
        "preview": preview
    }); 
    return multiSelect;   
}
