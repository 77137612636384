class Mutex {
    constructor() {
      this.current = Promise.resolve();
    }
  
    async acquire() {
      let release;
      const next = new Promise(resolve => {
        release = () => {
          resolve();
        };
      });
      const waiter = this.current.then(() => release);
      this.current = next;
      return await waiter;
    }
}