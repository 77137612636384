$(function() {
    $("body").on("click", ".sortable_trigger", function() {
        var selector = $(this).data("sortable");
        var parent = $(this).parents("table.pageit_sortable_table");
        
        parent.find(".sortable_indicator").remove();
        
        var icon = "keyboard_arrow_up";
        
        var oldSelector = parent.data("sort-item");
        var oldDirection = parent.data("sort-direction");
        
        var newDirection = "ASC";
        
        if (oldSelector == selector) {
            newDirection = oldDirection == "ASC" ? "DESC" : "ASC";
        } else {
            newDirection = oldDirection;
        }
        
        if (newDirection == "DESC") {
            icon = "keyboard_arrow_down";
        }
        
        parent.data("sort-item", selector);
        parent.data("sort-direction", newDirection);
        
        var fontsize = $(this).css("font-size");
        
        $(this).append(`<i class="material-icons sortable_indicator">${icon}</i>`);
        $(this).find(".sortable_indicator").css("font-size", fontsize);
        
        var childselector;
        
        if ($(this).parents("table.pageit_sortable_table").find("tbody").length) {
            parent = $(this).parents("table.pageit_sortable_table").find("tbody");
            childselector = "tr";
        } else {
            childselector = "tr";
        }
        
        var items = parent.children(childselector).sort(function(a,b) {
            var vA = $(a).find(selector).text();
            if ($(a).find(selector).data("sortable-value")) {
                vA = $(a).find(selector).data("sortable-value");
            }
            var vB = $(b).find(selector).text();
            if ($(b).find(selector).data("sortable-value")) {
                vB = $(b).find(selector).data("sortable-value");
            }
            var cmp = (vA < vB) ? -1 : (vA > vB) ? 1 : 0;
            if (newDirection == "ASC") {
                return cmp;
            }
            return cmp * -1;
        });
        $(parent).append(items);
    });

    $(".pageit_sortable_table").each(function() {
        var selector = $(this).data("sort-item");
        var dir = $(this).data("sort-direction");
        
        var elem = $(this).find('.sortable_trigger[data-sortable="'+selector+'"]');
                
        var fontsize = $(elem).css("font-size");
        var icon = dir == "ASC" ? "keyboard_arrow_up" : "keyboard_arrow_down";
        $(elem).append(`<i class="material-icons sortable_indicator">${icon}</i>`);
        $(elem).find(".sortable_indicator").css("font-size", fontsize);
    });
});