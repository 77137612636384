function initAdminColorInput(element) {
    if (element.parent().hasClass("pageit_color_input_wrapper")) {
        return;
    }
    element.wrap(`<div class="pageit_color_input_wrapper"></div>`);
    let wrapper = element.parent();
    let selector = $(`<input type="color" class="pageit_color_input_select" value="${element.val()}" />`);
    let deletor = $(`<button class="btn red darken-2"><i class="material-icons">delete</i></button>`);
    wrapper.prepend(deletor);
    wrapper.append(selector);
    element.on("change", function() {
        let color = $(this).val();
        $(this).parent().find("> .pageit_color_input_select").val(color);
    });
    selector.on("change", function() {
        element.val($(this).val());
    })
    deletor.on("click", function() {
        element.val("");
        selector.val("");
    });
    updategrids();
}

$(function() {
    $(".pageit_color_input").each(function() {
        initAdminColorInput($(this));
    });
});
