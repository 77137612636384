function pictureSelect(button, deletebtn, input, preview) {
    button.on("click", function() {
        var selectDlg = new Dialog();
        selectDlg.fileBrowser(
            "Bild auswählen",
            "Wählen Sie das Bild aus, welches Sie verwenden möchten.",
            "pictures",
            new Array(),
            true,
            function(pic) {
                $(preview).css("display", "inline-block");
                $(preview).attr("src", pic.thumbnail);
                $(preview).parent().find("span").text(`
                    #${pic.id} - ${pic.vpath}
                `);
                input.val(pic.id);
                selectDlg.close();
                updategrids();
            }, 
            function() {
                selectDlg.close();
            },
            {
                upload: true,
                foldercreation: true
            }
        );
    });

    deletebtn.on("click", function() {
        $(preview).hide();
        $(preview).parent().find("span").text("Kein Bild gewählt");
        input.val("-1");
    });
}

function initAdminPictureSelect(element) {
    let inputid = $(element).data("input");
    let val = $(element).data("value");
    let width = $(element).data("width") ? $(element).data("width") + "px" : "100px";
    let title = $(element).data("title") ? $(element).data("title") : "";
    let name = $(element).data("name") ? $(element).data("name") : "";
    
    $(element).html(`
        <label>${title}</label>
        <div class="valign-wrapper">
            <input type="hidden" id="${inputid}" name="${name}" value="${val}" />
            <img style="display: none; width: ${width}; margin-right: 20px;" class="adminpictureselect_preview" />
            <span>Kein Bild gewählt</span>
        </div>
        <div class="right-align" style="margin-top: 10px;">
            <button type="button" class="btn red darken-2 white-text waves-effect tooltipped adminpictureselect_delete" data-position="top" data-tooltip="Bild l&ouml;schen">
                <i class="material-icons left" style="margin-right: 0px;">delete</i>
            </button>
            <button type="button" class="btn blue white-text waves-effect adminpictureselect_select">
                <i class="material-icons left">photo</i>
                Bild wählen
            </button>
        </div>
    `);
    
    var btn = $(element).find(".adminpictureselect_select");
    var del = $(element).find(".adminpictureselect_delete");
    var preview = $(element).find(".adminpictureselect_preview");
    var input = $(element).find("input");
    
    $.ajax({
        url: "api/pictures/info",
        type: "POST",
        data: {
            id: val                
        },
        success: function(resp) {
            if (resp.picture) {
                var pic = resp.picture;
                preview.css("display", "inline-block");
                preview.attr("src", pic.thumbnail);
                preview.parent().find("span").text(`#${pic.id} - ${pic.vpath}`);
                updategrids();
            }
        }            
    });
    
    pictureSelect(btn, del, input, preview);
}

$(function() {
    $(".adminpictureselect").each(function() {
        initAdminPictureSelect($(this));
    });
});
